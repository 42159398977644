import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './app/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { jsx as _jsx } from 'react/jsx-runtime';

var container = document.getElementById('root');
var root = createRoot(container);
root.render(
  _jsx(React.StrictMode, {
    children: _jsx(Provider, {
      store: store,
      children: _jsx(BrowserRouter, { children: _jsx(App, {}) }),
    }),
  })
);
