import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { api as ushipApi } from '../app/services/api';
import shippingFormSlice from '../features/uship/shippingFormSlice';
// import fakeglobalsSlice from '../features/fakeglobals/fakeglobalsSlice';

export const store = configureStore({
  reducer: {
    //[shippingFormApi.reducerPath]: shippingFormApi.reducer,
    [ushipApi.reducerPath]: ushipApi.reducer,
    [shippingFormSlice.name]: shippingFormSlice.reducer,
    // fakeglobals: fakeglobalsSlice.reducer,
    auth: authReducer,
  },

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(ushipApi.middleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
