
import './App.css';
import { ShippingForm } from './features/components/ShippingForm';
import { Route, Routes, useNavigate } from 'react-router';
import OktaAuth from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import AuthedRoute from './features/auth/AuthedRoute';
import { OrderEntry } from './features/components/OrderEntry';
import oktaConfig from './config/okta-config';


const App = () => {

  const navigate = useNavigate()
  const oktaAuth = new OktaAuth(oktaConfig);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={async (_oktaAuth, originalUri) => {
        navigate(originalUri);
      }}
    >
      <Routes>
        <Route path='login' element={<LoginCallback />} />
        <Route path='' element={<AuthedRoute />}>
          <Route path='/' element={<OrderEntry />} />
          <Route path='/:id' element={<ShippingForm />} />
        </Route>
      </Routes>
    </Security>
  );
};

export default App;
