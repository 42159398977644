import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';
import { baseApiUrl } from '../../config/api-config';
import {
  clearAccessToken,
  selectAuthBearerHeader,
} from '../../features/auth/authSlice';

interface ErrorResponse {
  msg: string;
  respBodyFromFedex: string;

}

const axiosBaseQuery = async (
  argsFromEndpoint: any,
  { getState, dispatch }: any
) => {
  console.log(`args: ${JSON.stringify(argsFromEndpoint)}`);

  const args =
    typeof argsFromEndpoint === 'string'
      ? { url: argsFromEndpoint, method: 'get' }
      : argsFromEndpoint;

  if (args.data) args.body = args.data;
  if (!args.method) args.method = 'get';
  if (!args.baseURL) args.url = `${baseApiUrl}/${args.url}`;
  args.headers = {
    ...(args.headers || {}),
    Authorization: `${selectAuthBearerHeader(getState())}`,
  };
  args.withCredentials = true;

  // console.log(`axios args: ${JSON.stringify(args)}`);

  try {
    const ans = await axios(args);
    // console.log(`axios success! -  ${JSON.stringify(ans)}`);

    if (ans?.status === 401 || ans?.status === 403) {
      dispatch(clearAccessToken());
    }

    if (ans?.status >= 300) {
      console.log(`error! - ${JSON.stringify(ans)}`);
      return {
        error: {
          message: ans.data,
          status: ans.status,
        },
      };
    }
    return { data: ans.data };
  } catch (err) {
    console.log(`axios error! - ${JSON.stringify(err)}`);
    if (!(err instanceof AxiosError))
      return { error: `unknown error type - ${err}` };

    const aerr = err as AxiosError;
    const status = aerr.response?.status || -1;
    const serverMessage = (aerr.response?.data as ErrorResponse)?.msg;
    const respBodyFromFedex = (aerr.response?.data as ErrorResponse)?.respBodyFromFedex;

    if (status === 401 || status === 403) {
      console.log(`401/403 caught!`);
      dispatch(clearAccessToken());
    }



    return {
      error: {
        status: status ? (status as number) : -1,
        message: serverMessage || `HEY FROM CATCH - ${aerr.message}`,
        fedexResponse: respBodyFromFedex,
      },
    };
  }
};

export const api = createApi({
  baseQuery: axiosBaseQuery,
  tagTypes: ['uship'],
  endpoints: () => ({}),
});
