import { Figure, Table } from "react-bootstrap";

interface DateTableProps {
    caption: string;
    dateValues: {
        month: string;
        day: string;
        year: string;
    };
}

const DateTable: React.FC<DateTableProps> = ({ caption, dateValues }) => (
    <>
        <Figure.Caption>{caption}</Figure.Caption>
        <Table striped bordered hover size="sm">
            <tbody>
                <tr>
                    <td>{dateValues.month}</td>
                    <td>{dateValues.day}</td>
                    <td>{dateValues.year}</td>
                </tr>
            </tbody>
        </Table>
    </>
);

export default DateTable;