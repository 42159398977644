import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Uship } from './types';

interface ShippingState {
  shipping: Uship;
}

const initialState: ShippingState = {
  shipping: {
    masterTrackingNumber: '',
    orderNumber: '',
    priority: '',
    recipientName: '',
    recipientPhone: '',
    boxCount: 0,
    expectedDeliveryDate: '',
    actualDeliveryDate: '',
    addresses: [],
    shipOptions: [],
    selectedShipOptions: {},
    pieceInfos: [],
    status: '',
  },
};

export const shippingFormSlice = createSlice({
  name: 'uship',
  initialState,
  reducers: {
    updateShippingFields: (state, action) => {
      const { key, newval } = action.payload;

      const { shipping } = state;
      return {
        ...state,
        shipping: {
          ...shipping,
          [key]: newval,
        },
      };
    },

    setCurrShipping: (state, action) => {
      const { shipping } = action.payload;
      return {
        ...state,
        shipping,
      };
    },

    unsetCurrShipping: (state): void => {
      state.shipping.orderNumber = '';
    },
  },
});

export const selectCurrShipping = (state: RootState): Uship =>
  state.uship && state.uship.shipping;

export const selectCurrShippingExists = (state: RootState): Uship =>
  state.uship && state.uship.shipping;

export const { updateShippingFields, setCurrShipping, unsetCurrShipping } =
  shippingFormSlice.actions;

export default shippingFormSlice;
