import { Button, Card, Col, Container, Row, Stack, Modal, Form, Image } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router';
import React, { useEffect, useState } from "react";
import { useCancelUshipMutation, useCreateUshipMutation, useGetUshipQuery } from "../uship/ushipApi";
import '../style/ShippingForm.css'
import { clearAccessToken } from "../auth/authSlice";
import { useAppDispatch } from "../../app/hooks";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Field, Formik, Form as FormikForm } from "formik";
import * as Yup from 'yup'
import { ShipOptions, Uship } from '../uship/types';
import { statesByCountry } from './StateOrProvinceSelect';
import '../../theme/components/button.css'
import '../../theme/components/label.css'
import DateTable from "./form-components/DateTable";
import uLab_Horiz_Logo from '../../assets/images/uLab_Horiz_Logo.png'
import { useOktaAuth } from "@okta/okta-react"



export const ShippingForm = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { oktaAuth } = useOktaAuth()
    const { id } = useParams()
    const [orderNumber] = useState(id || '')
    const { data: ushipData, isLoading: isUshipLoading, isError: isUshipError, error: ushipError } = useGetUshipQuery(orderNumber);
    const [createUship, { isSuccess, error }] = useCreateUshipMutation();
    const postalCodePattern = /^(?:(?:[0-9]{5}(?:-[0-9]{4})?)|([a-zA-Z]\d[a-zA-Z] ?\d[a-zA-Z]\d))$/
    const recipientPhonePattern = /^(\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4})$|^[+]{1}(?:[0-9\-\(\)\/\.]\s?){6,15}[0-9]{1}$/
    const [isPrinting, setIsPrinting] = useState(false);

    // const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [httpStatusCode, setHttpStatusCode] = useState<number | null>(null);


    const [expectedDeliveryDate, setExpectedDeliveryDate] = useState((ushipData && ushipData.expectedDeliveryDate) || '')
    const [expectedDateValues, setExpectedDateValues] = useState({ year: '', month: '', day: '' })
    const [actualDeliveryDate, setActualDeliveryDate] = useState((ushipData && ushipData?.actualDeliveryDate) || '')
    const [selectedShippingOption, setSelectedShippingOption] = useState('');
    const [cheapestFastestOption, setCheapestFastestOption] = useState<ShipOptions | null>(null);
    const [fastestOption, setFastestOption] = useState<ShipOptions | null>(null);
    const [shouldNavigate, setShouldNavigate] = useState(false);
    const [lastChosenOption, setLastChosenOption] = useState((ushipData && ushipData?.overrides?.service_type) || '');

    const intUSStates = ["AS", "GU", "MP", "PR", "UM", "VI"]

    const filterOptions = (options: any[]) => {
        return options.filter((option) => {
            const deliveryDate = new Date(option.deliveryDate)
            const daysToGetThere = option.daysToGetThere
            const deliveryDay = deliveryDate.getDay()

            if (deliveryDay === 6 || deliveryDay === 0) {
                return false;
            }

            const minimumDaysAllowed = 1
            return daysToGetThere >= minimumDaysAllowed
        })
    }

    const sortOptions = (options: any[]) => {
        return options.sort((a, b) => {
            if (a.cost !== b.cost) {
                return a.cost - b.cost
            }
            return a.daysToGetThere - b.daysToGetThere
        })
    }
    const defaultOptions = React.useMemo(() => {
        if (!ushipData || !ushipData.addresses || ushipData.addresses.length === 0) {
            // ushipData or ushipData.addresses is undefined, or ushipData.addresses is empty
            // Return a default value or do nothing
            return null;
        }

        const isUSCountry = ushipData.addresses[0].countryCode === "US";
        const isIntUSState = intUSStates.includes(ushipData.addresses[0].stateOrProvinceCode || '');

        if ((!ushipData.shipOptions || ushipData.shipOptions.length === 0) && (isUSCountry && !isIntUSState)) {
            // Return default options when ushipData.shipOptions is empty
            return ["FEDEX_EXPRESS_SAVER", "FEDEX_2_DAY", "STANDARD_OVERNIGHT", "PRIORITY_OVERNIGHT"];
        } else {
            return ["INTERNATIONAL_ECONOMY", "INTERNATIONAL_PRIORITY"];
        }

    }, [ushipData]);

    const initialSelectedShipOption = React.useMemo(() => {
        if (!ushipData) {
            console.log('ushipData is not defined');
            return null;
        }

        console.log('ushipData:', ushipData);

        const { addresses, shipOptions } = ushipData;

        if (!addresses || addresses.length === 0) {
            console.log('No addresses available');
            return null;
        }

        const { countryCode, stateOrProvinceCode } = addresses[0];
        const isUSCountry = countryCode === "US";
        const isIntUSState = intUSStates.includes(stateOrProvinceCode || '');

        console.log(`isUSCountry: ${isUSCountry}, isIntUSState: ${isIntUSState}`);

        if (!shipOptions || shipOptions.length === 0) {
            if (isUSCountry && isIntUSState) {
                console.log('Returning INTERNATIONAL_ECONOMY');
                const internationalEconomyOptions = ushipData?.shipOptions?.filter(option =>
                    ["INTERNATIONAL_ECONOMY"].includes(option.serviceType || '')
                );
                return internationalEconomyOptions && internationalEconomyOptions.length > 0 ? internationalEconomyOptions[0] : null;
            }
            console.log('No ship options, returning null');
            return null;
        }

        const filteredOptions = filterOptions(shipOptions);
        console.log(`filteredOptions: ${JSON.stringify(filteredOptions)}`);

        if (!filteredOptions || filteredOptions.length === 0) {
            console.log('No filtered options, returning null');
            return null;
        }


        // Filter out the options that will be late
        const onTimeOptions = filteredOptions.filter(option => {
            let deliveryDate = new Date(option.deliveryDate);
            deliveryDate.setHours(0, 0, 0, 0);
            const [year, month, day] = expectedDeliveryDate.split('-').map(Number);
            let expectedDate = new Date(year, month - 1, day);
            expectedDate.setHours(0, 0, 0, 0);
            console.log(`deliveryDate: ${deliveryDate}, expectedDate: ${expectedDate}`);
            return deliveryDate <= expectedDate;
        });

        // Filter out the PRIORITY_OVERNIGHT option
        const nonPriorityOptions = onTimeOptions.filter(option => option.serviceType !== 'PRIORITY_OVERNIGHT' && option.serviceType !== 'FEDEX_INTERNATIONAL_PRIORITY');

        // If there are no on time non-priority options, select the fastest option
        if (!nonPriorityOptions || nonPriorityOptions.length === 0) {
            const sortedBySpeedOptions = filteredOptions.sort((a, b) => a.daysToGetThere - b.daysToGetThere);
            if (sortedBySpeedOptions && sortedBySpeedOptions.length > 0) {
                const fastestOption = sortedBySpeedOptions[0];
                setFastestOption(fastestOption); // return the second fastest option if the fastest is PRIORITY_OVERNIGHT
                console.log(`fastestOption: ${JSON.stringify(fastestOption)}`);
                return fastestOption;
            }
            return null;
        }

        console.log(`nonPriorityOptions: ${JSON.stringify(nonPriorityOptions)}`);
        // Sort the on time non-priority options
        const sortedNonPriorityOptions = sortOptions(nonPriorityOptions);
        console.log(`sortedNonPriorityOptions: ${JSON.stringify(sortedNonPriorityOptions)}`);

        // Select the first option (cheapest and fastest)
        if (sortedNonPriorityOptions && sortedNonPriorityOptions.length > 0) {
            const cheapestFastestOption = sortedNonPriorityOptions[0];
            setCheapestFastestOption(cheapestFastestOption);
            console.log(`cheapestFastestOption: ${JSON.stringify(cheapestFastestOption)}`);
            return cheapestFastestOption;
        }
        return null;
    }, [ushipData, expectedDeliveryDate]);

    const filteredShipOptions = ushipData?.shipOptions ? filterOptions(ushipData?.shipOptions) : [];
    console.log({ filteredShipOptions })


    const schema: Yup.ObjectSchema<Uship> = Yup.object({
        masterTrackingNumber: Yup.string(),
        orderNumber: Yup.string().required('Required'),
        priority: Yup.string().required('Required'),
        recipientName: Yup.string().required('Required'),
        recipientPhone: Yup.string().test(
            'is-US-or-CA-phone-number',
            'Invalid phone number',
            function (value) {
                const { addresses } = this.parent;
                const countryCode = addresses[0].countryCode;
                if (countryCode === 'US' || countryCode === 'CA') {
                    // If countryCode is 'US' or 'CA', recipientPhone must match the recipientPhonePattern
                    return recipientPhonePattern.test(value || '');
                } else {
                    // Otherwise, no specific validation is applied
                    return true;
                }
            }
        ).required('Required'),
        boxCount: Yup.number().required('Required').min(1, 'Must be greater than 0'),
        expectedDeliveryDate: Yup.string(),
        actualDeliveryDate: Yup.string(),
        addresses: Yup.array(Yup.object({
            streetLines: Yup.array()
                .of(Yup.string().required('Required').max(35))
                .required('Required'),
            city: Yup.string().required('Required'),
            stateOrProvinceCode: Yup.string().test(
                'is-state-required',
                'Required',
                function (value) {
                    const { countryCode } = this.parent;
                    return countryCode !== 'NZ' ? !!value : true;
                }
            ),
            countryCode: Yup.string().required('Required'),
            postalCode: Yup.string().test(
                'is-NZ-or-AU-postal-code',
                'Invalid postal code',
                function (value) {
                    const { countryCode } = this.parent;
                    if (countryCode === 'NZ' || countryCode === 'AU') {
                        // If countryCode is 'NZ' or 'AU', postalCode must be 4 digits
                        return /^\d{4}$/.test(value || '');
                    } else {
                        // Otherwise, use the original pattern
                        return postalCodePattern.test(value || '');
                    }
                }
            ).required('Required'),

        })
        )
            .default([]),
        shipOptions: Yup.array().of(
            Yup.object().shape({
                cost: Yup.number(),
                serviceName: Yup.string(),
                daysToGetThere: Yup.number(),
                deliveryDate: Yup.string(),
                serviceType: Yup.string(),
            })
        ).required(),
        selectedShipOptions: Yup.string(),
        pieceInfos: Yup.array(Yup.object({
            trackingNumber: Yup.string(),
            zpl: Yup.string(),
        })),
        status: Yup.string(),
        overrides: Yup.object({
            service_type: Yup.string().default(''),
        }),
    })


    const initialFormValue: Uship = {
        masterTrackingNumber: '',
        orderNumber: '',
        priority: '',
        recipientName: '',
        recipientPhone: '',
        boxCount: 0,
        expectedDeliveryDate: '',
        actualDeliveryDate: '',
        addresses: [
            {
                streetLines: [''],
                city: '',
                stateOrProvinceCode: '',
                postalCode: '',
                countryCode: '',
            }
        ],
        shipOptions: [
            {
                cost: 0,
                serviceName: '',
                daysToGetThere: 0,
                deliveryDate: '',
                serviceType: '',
            }
        ],
        selectedShipOptions: initialSelectedShipOption ? initialSelectedShipOption.serviceType : filteredShipOptions[0]?.serviceType,
        pieceInfos: [
            {
                trackingNumber: '',
                zpl: '',
            }
        ],
        status: '',
    }


    // Initialize other fields as needed using data from ushipData
    if (!isUshipLoading && !isUshipError && ushipData) {
        initialFormValue.orderNumber = ushipData.orderNumber
        initialFormValue.priority = ushipData.priority
        initialFormValue.recipientName = ushipData.recipientName
        initialFormValue.recipientPhone = ushipData.recipientPhone
        initialFormValue.boxCount = ushipData.boxCount
        initialFormValue.expectedDeliveryDate = ushipData.expectedDeliveryDate
        initialFormValue.actualDeliveryDate = ushipData.actualDeliveryDate
        // Access and assign the streetLines property of the first address

        if (ushipData.addresses.length > 0) {
            initialFormValue.addresses[0] = {
                streetLines: [ushipData.addresses[0].streetLines[0]],
                city: ushipData.addresses[0].city,
                stateOrProvinceCode: ushipData.addresses[0].stateOrProvinceCode,
                postalCode: ushipData.addresses[0].postalCode,
                countryCode: ushipData.addresses[0].countryCode,
            };

            if (ushipData.addresses[0].streetLines.length >= 2) {
                initialFormValue.addresses[0].streetLines.push(ushipData.addresses[0].streetLines[1])
            }
        }
        if (ushipData.shipOptions.length > 0) {
            if (ushipData.shipOptions.length > 0) {
                initialFormValue.shipOptions = ushipData.shipOptions.map(option => ({
                    cost: option.cost,
                    serviceName: option.serviceName,
                    daysToGetThere: option.daysToGetThere,
                    deliveryDate: option.deliveryDate,
                    serviceType: option.serviceType
                }))
            } else {
                initialFormValue.shipOptions = []
            }
        }
    }

    const buildFormData = (values: any) => {
        const formData = new FormData()


        formData.append('order_number', values.orderNumber)
        formData.append('phone_number', values.recipientPhone)
        formData.append('number_of_fedex_boxes', values?.boxCount)
        formData.append('address_line', values.addresses[0].streetLines[0])
        if (values.addresses[0].streetLines[1]?.length > 0) {
            formData.append('address_line', values.addresses[0].streetLines[1] || '')
        }
        formData.append('city', values.addresses[0].city)
        formData.append('state', values.addresses[0].stateOrProvinceCode || '')
        formData.append('postal_code', values.addresses[0].postalCode)
        formData.append('country_code', values.addresses[0].countryCode)

        formData.append('service_type',
            values.selectedShipOptions ? values.selectedShipOptions : initialSelectedShipOption?.serviceType
        );

        return formData

    }

    const handleSubmits = async (values: Uship) => {
        console.log({ values });

        console.log('Form submitted!');
        const formData = buildFormData(values);
        console.log({ formData });

        try {
            const result = await createUship({ formData });
            console.log({ result })

            // Call handlePrint after createUship is successful
            if ('data' in result && result.data) {
                // Check if electronAPI is null or undefined
                if ((window as any).electronAPI) {
                    if (!result.data || !result.data.pieceInfos) {
                        console.error('ushipData or pieceInfos is undefined');
                        return;
                    }
                    // Loop over the pieceInfos array and print the label for each item
                    for (let i = 0; i < result.data?.pieceInfos?.length; i++) {
                        const zpl = result.data?.pieceInfos?.[i]?.zpl;
                        const printStatus = await (window as any).electronAPI.printSilently(zpl);
                        console.log({ zpl, printStatus });

                        if (printStatus.successful) {
                            console.log('Print successful');
                        } else {
                            console.error('Print failed:', printStatus.simpleError);
                            console.error('Detailed error:', printStatus.detailedError);
                        }

                        // Wait for 1 second before starting the next print job
                        await new Promise(resolve => setTimeout(resolve, 1000));
                    }
                } else {
                    console.error('electronAPI is not available');
                }
            }
            return Promise.resolve();
        } catch (error: any) {
            console.log({ error })
            if (error && typeof error === 'object' && 'data' in error) {
                const errorData = error.data;
                if ('msg' in errorData) {
                    console.log({ error })
                    let errorMessage = errorData.msg;
                    if ('status' in errorData) {
                        errorMessage += `-${errorData.status}`;
                    }
                    setErrorMessage(`${errorData.status}-${errorData.status}`);
                    setHttpStatusCode(error.status);
                    handleShowErrorModal();
                }
                return { ...errorData };
            }
        }
    }

    useEffect(() => {
        if (isSuccess) {
            navigate('/')
            // handleShowSuccessModal();
        }
        if (error && typeof error === 'object' && 'message' in error && 'status' in error) {
            setErrorMessage(`${error.message}`);
            setHttpStatusCode(error.status);
            handleShowErrorModal();
        } else if (typeof error === 'string') {
            setErrorMessage(`${error}`);
            handleShowErrorModal();
        }
    }, [isSuccess, error, navigate]);

    // const handleShowSuccessModal = () => {
    //     setShowSuccessModal(true);
    // };

    const handleShowErrorModal = () => {
        console.log('handleShowErrorModal called');
        setShowErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        if (shouldNavigate) {
            navigate('/');
        }
    };


    const [cancelUship, { isSuccess: isSuccessCancel, error: errorCancel }] = useCancelUshipMutation();

    useEffect(() => {
        if (isSuccessCancel) {
            navigate('/')
            // handleShowSuccessModal();
        }
        if (errorCancel && typeof errorCancel === 'object' && 'message' in errorCancel && 'status' in errorCancel) {
            setErrorMessage(`${errorCancel.message}`);
            setHttpStatusCode(errorCancel.status);
            handleShowErrorModal();
        } else if (typeof errorCancel === 'string') {
            setErrorMessage(`${errorCancel}`);
            handleShowErrorModal();
        }
    }, [isSuccessCancel, errorCancel, navigate]);

    const logout = async () => {
        oktaAuth.signOut();
        navigate('login')
    }

    useEffect(() => {
        const [year, month, day] = expectedDeliveryDate.split('-')
        setExpectedDateValues({
            year,
            month,
            day,
        })
    }, [expectedDeliveryDate])

    const handleShippingOptionChange = (option: string, deliveryDate: string, handleChange: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedShippingOption(option as "FEDEX_EXPRESS_SAVER" | "FEDEX_2_DAY" | "STANDARD_OVERNIGHT" | "PRIORITY_OVERNIGHT" | "INTERNATIONAL_ECONOMY" | "INTERNATIONAL_PRIORITY");
        setActualDeliveryDate(deliveryDate);
        handleChange(event);  // Call Formik's handleChange function
    };

    useEffect(() => {
        // If cheapestFastestOption is defined
        if (cheapestFastestOption) {
            // Set actualDeliveryDate to the deliveryDate of cheapestFastestOption
            setActualDeliveryDate(cheapestFastestOption.deliveryDate || '');
        }
    }, [cheapestFastestOption]); // This effect runs whenever cheapestFastestOption changes

    useEffect(() => {
        if (fastestOption) {
            setActualDeliveryDate(fastestOption.deliveryDate || '');
        }
    }, [fastestOption]);

    const convertDateToValues = (dateString: string) => {
        const date = new Date(dateString);

        // If date is not valid, return default values
        if (isNaN(date.getTime())) {
            return {
                year: '',
                month: '',
                day: '',
            };
        }

        return {
            year: date.getFullYear().toString(),
            month: (date.getMonth() + 1).toString(),
            day: date.getDate().toString(),
        };
    };

    if (isUshipLoading) {
        return <div>Loading Uship data...</div>
    }

    if (isUshipError) {
        return <div>Error fetching Uship data: {typeof ushipError === 'object' ? ushipError.message : ushipError} </div>
    }

    console.log(`in actual form: orderNumber: ${orderNumber}`)

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const handlePrint = async (values: Uship, handleClose: () => void) => {
        setIsPrinting(true);
        // Fetch the Uship data using the useGetUshipQuery hook
        if (ushipData) {
            // Check if electronAPI is null or undefined
            if ((window as any).electronAPI) {
                console.log(`Box count: ${values.boxCount}`);
                if (!ushipData || !ushipData.pieceInfos) {
                    console.error('ushipData or pieceInfos is undefined');
                    setIsPrinting(false);
                    return;
                }
                // Loop over the pieceInfos array and print the label for each item
                for (let i = 0; i < ushipData?.pieceInfos?.length; i++) {
                    const zplData = ushipData?.pieceInfos?.[i]?.zpl;
                    console.log(`Starting print job for box ${i + 1}`);
                    const printStatus = await (window as any).electronAPI.printSilently(zplData);
                    console.log(`Print status for box ${i + 1}:`, printStatus);

                    if (printStatus.successful) {
                        console.log('Print successful');
                    } else {
                        console.error('Print failed:', printStatus.simpleError);
                    }

                    // Wait for 1 second before starting the next print job
                    console.log(`Waiting for 1 second before starting the next print job`);
                    await delay(1000);
                }
                handleClose();
            } else {
                console.error('electronAPI is not available');
            }
        }
        setIsPrinting(false);
    }


    // const SuccessModal = () => (
    //     <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
    //         <Modal.Header closeButton>
    //             <Modal.Title>Success!</Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>Your request was successful!</Modal.Body>
    //         <Modal.Footer>
    //             <Button variant="secondary" onClick={() => { setShowSuccessModal(false); navigate('/') }}>
    //                 Close
    //             </Button>
    //         </Modal.Footer>
    //     </Modal>
    // );

    type ErrorModalProps = {
        show: boolean;
        handleClose: () => void;
        errorMessage: string;


    }

    const ErrorModal = ({ show, handleClose, errorMessage }: ErrorModalProps, values: Uship) => {

        const handleReprint = () => {
            handlePrint(values, handleClose)
                .then(() => {
                    navigate('/');
                })
                .catch((error) => {
                    console.error('Error during printing:', error);
                });
        };
        return (

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    {httpStatusCode === 409 && (
                        <Button className="button" onClick={handleReprint} disabled={isPrinting} >
                            {isPrinting ? (
                                <>
                                    <span className='spinner-grow spinner-grow-sm' aria-hidden='true'></span>
                                    <span role="status">Printing...</span>
                                </>) : 'Reprint'}
                        </Button>
                    )}
                    {!isPrinting && (
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        )
    };



    const handleCancel = async () => {
        const result = await cancelUship(orderNumber);
        if ('error' in result) {
            console.error('Failed to cancel Uship:', result.error);
            if (result.error && typeof result.error === 'object' && 'message' in result.error && 'status' in result.error) {
                setErrorMessage(`${result.error.message}`);
                setHttpStatusCode(result.error.status);
                handleShowErrorModal();
            } else if (typeof result.error === 'string') {
                setErrorMessage(`${result.error}`);
                handleShowErrorModal();
            }
        } else {
            navigate('/');
        }
    }

    const handleNewShipment = () => {
        navigate('/')
    }

    const isFormReadOnly = Boolean(ushipData?.status === 'shipment_created');


    return (<>
        {/* <div > */}
        <Container>
            {/* <SuccessModal /> */}
            <ErrorModal show={showErrorModal} handleClose={handleCloseErrorModal} errorMessage={errorMessage} />
        </Container>
        {/* <Container> */}
        <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Card className="shadow" style={{ width: '90%' }}>
                <Formik
                    validationSchema={schema}
                    initialValues={initialFormValue}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmits(values)
                            .then(() => {
                                resetForm();
                                setShouldNavigate(true); // Set shouldNavigate to true after successful print
                            })
                            .catch((error) => {
                                console.error('Error during printing:', error);
                            });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        handleBlur,
                    }) => {
                        console.log('Is submitting:', isSubmitting);
                        console.log('Formik errors:', errors);
                        return (
                            <FormikForm noValidate
                                onSubmit={async (event) => {
                                    event.preventDefault();
                                    handleSubmit(event)
                                }}
                            >
                                <CardHeader>
                                    <Card.Title>
                                        <Form.Label><h1>{values.orderNumber} / <u>{values.priority.toUpperCase()}</u></h1></Form.Label>
                                    </Card.Title>
                                </CardHeader>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Label><h3>{values.recipientName}</h3></Form.Label>

                                            {isFormReadOnly && (
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Tracking Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="masterTrackingNumber"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={ushipData?.masterTrackingNumber}
                                                        readOnly={isFormReadOnly}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.masterTrackingNumber}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            )}

                                            <Form.Group className="mb-3 mt-3 " >
                                                <Form.Label><h6>Phone Number</h6></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="recipientPhone"
                                                    placeholder="Phone Number"
                                                    value={values.recipientPhone}
                                                    onChange={handleChange}
                                                    isValid={touched.recipientPhone && !errors?.recipientPhone}
                                                    isInvalid={!!errors.recipientPhone}
                                                    readOnly={isFormReadOnly}
                                                />
                                                <Form.Control.Feedback>
                                                    Looks Good!
                                                </Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.recipientPhone}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label><h6>Address</h6></Form.Label>
                                                <Stack direction="vertical" gap={3}>
                                                    <Form.Control
                                                        type="text"
                                                        name="addresses[0].streetLines[0]"
                                                        placeholder="Address 1"
                                                        value={values.addresses[0].streetLines[0]}
                                                        onChange={handleChange}
                                                        isValid={touched.addresses && !errors.addresses}
                                                        isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'streetLines' in errors.addresses[0] ? errors.addresses[0].streetLines?.[0] : undefined)}
                                                        readOnly={isFormReadOnly}
                                                    />
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.addresses && typeof errors.addresses[0] !== 'string' && errors.addresses[0].streetLines?.[0]}
                                                    </Form.Control.Feedback>
                                                </Stack>
                                            </Form.Group>

                                            <Form.Group className="mb-3 mt-3">
                                                <Stack direction="vertical" gap={3}>
                                                    <Form.Control
                                                        type="text"
                                                        name="addresses[0].streetLines[1]"
                                                        placeholder="Address 2"
                                                        value={values.addresses[0].streetLines[1]}
                                                        onChange={handleChange}
                                                        isValid={touched.addresses && !errors.addresses}
                                                        isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'streetLines' in errors.addresses[0] ? errors.addresses[0].streetLines?.[1] : undefined)}
                                                        readOnly={isFormReadOnly}
                                                    />
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.addresses && typeof errors.addresses[0] !== 'string' && errors.addresses[0].streetLines?.[1]}
                                                    </Form.Control.Feedback>
                                                </Stack>

                                            </Form.Group>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} xs={5}>
                                                    <Form.Label><h6>City</h6></Form.Label>
                                                    <Form.Control
                                                        type='text'
                                                        placeholder='City'
                                                        name="addresses[0].city"
                                                        value={values.addresses[0].city}
                                                        onChange={handleChange}
                                                        isValid={touched.addresses?.[0]?.city && !(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'city' in errors.addresses[0] ? errors.addresses[0].city : undefined)}
                                                        isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'city' in errors.addresses[0] ? errors.addresses[0].city : undefined)}
                                                        readOnly={isFormReadOnly}
                                                    />
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && errors.addresses[0].city}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <label htmlFor="addresses[0].stateOrProvinceCode"><Form.Label><h6>State</h6></Form.Label></label>
                                                    <Form.Select
                                                        name="addresses[0].stateOrProvinceCode"
                                                        onChange={handleChange}
                                                        value={values.addresses[0].stateOrProvinceCode}
                                                        isValid={touched.addresses && !errors.addresses}
                                                        isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'stateOrProvinceCode' in errors.addresses[0] ? errors.addresses[0].stateOrProvinceCode : undefined)}
                                                        disabled={isFormReadOnly || values.addresses[0].countryCode === 'NZ'}
                                                    >
                                                        <option value=''>Select a state</option>
                                                        {statesByCountry.hasOwnProperty(values.addresses[0].countryCode) &&
                                                            Object.keys(statesByCountry[values.addresses[0].countryCode]).map((state) => (
                                                                <option key={state} value={state}>
                                                                    {state}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.addresses && typeof errors.addresses[0] !== 'string' && errors.addresses[0].stateOrProvinceCode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label><h6>Country</h6></Form.Label>
                                                    <Form.Select
                                                        name="addresses[0].countryCode"
                                                        onChange={handleChange}
                                                        value={values.addresses[0].countryCode}
                                                        isValid={touched.addresses && !errors.addresses}
                                                        isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'countryCode' in errors.addresses[0] ? errors.addresses[0].countryCode : undefined)}
                                                        disabled={isFormReadOnly}
                                                    >
                                                        <option value=''>Select Country</option>
                                                        {Object.keys(statesByCountry).map((countryCode) => (
                                                            <option key={countryCode} value={countryCode}>
                                                                {countryCode}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.addresses && typeof errors.addresses[0] !== 'string' && errors.addresses[0].countryCode}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Form.Group className="mb-3">
                                                <Stack direction="vertical" >
                                                    <Row className="align-items-center">
                                                        <Col xs="auto">
                                                            <Form.Label><h6>Postal Code</h6></Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                name="addresses[0].postalCode"
                                                                placeholder='Postal Code'
                                                                value={values.addresses[0].postalCode}
                                                                onChange={handleChange}
                                                                isValid={touched.addresses && !errors.addresses}
                                                                isInvalid={!!(errors.addresses?.[0] && typeof errors.addresses[0] === 'object' && 'postalCode' in errors.addresses[0] ? errors.addresses[0].postalCode : undefined)}
                                                                readOnly={isFormReadOnly}
                                                            />
                                                            <Form.Control.Feedback>
                                                                Looks Good!
                                                            </Form.Control.Feedback>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.addresses && typeof errors.addresses[0] !== 'string' && errors.addresses[0].postalCode}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Row>
                                                </Stack>
                                            </Form.Group>
                                            <div className="d-grid gap-2">
                                                <div className="d-grid">
                                                    <Button variant="light" type="submit" disabled={isSubmitting} style={{ height: '90px', backgroundColor: "#35C749" }} >
                                                        {isSubmitting ? (
                                                            <>


                                                                {/* <span className='spinner-grow spinner-grow-sm' aria-hidden='true'></span> */}


                                                                <span role="status"><h6>Printing...</h6></span>

                                                            </>) : <h3>Print Label</h3>
                                                        }

                                                    </Button>
                                                </div>
                                                <Row className="justify-content-center">
                                                    <Col xs={6} className="text-center">
                                                        <Button variant="secondary" className="button-fixed-size mr-3" onClick={handleNewShipment}>
                                                            <h6>New Shipment</h6>
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6} className="text-center">
                                                        <Button variant="secondary" className="button-fixed-size ml-3" onClick={logout}>
                                                            <h6>Logout</h6>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <Row className="justify-content-center">
                                                        <Col xs={12} className="text-center">

                                                            <Button variant="danger" onClick={handleCancel} disabled={ushipData?.status !== 'shipment_created'}>Cancel</Button>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <h6>Package Count</h6>
                                            <Row className="align-items-center">
                                                <Col xs='auto'>
                                                    <Form.Control
                                                        type='text'
                                                        name="boxCount"
                                                        placeholder='Package Count'
                                                        value={values.boxCount}
                                                        onChange={handleChange}
                                                        isValid={touched.boxCount && !errors.boxCount}
                                                        isInvalid={!!errors.boxCount}
                                                        readOnly={isFormReadOnly}
                                                    />
                                                    <Form.Control.Feedback>
                                                        Looks Good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.boxCount}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                            <hr className=" mb-5" />

                                            <h6>FedEx Ship Method</h6>
                                            <Stack direction="vertical">
                                                {filteredShipOptions?.length === 0 ?
                                                    (console.log({ lastChosenOption }),
                                                        defaultOptions?.map((shipOption, index) => (
                                                            <label key={index} className="labelStyle">

                                                                <Field
                                                                    type="radio"
                                                                    name="selectedShipOptions"
                                                                    value={shipOption}
                                                                    checked={isFormReadOnly ? lastChosenOption === shipOption : values.selectedShipOptions === shipOption}
                                                                    disabled={isFormReadOnly}
                                                                    onChange={isFormReadOnly ? undefined : handleChange}
                                                                />
                                                                {shipOption}
                                                            </label>
                                                        ))
                                                    ) :
                                                    (console.log({ lastChosenOption }),
                                                        filteredShipOptions?.map((shipOption, index: any) => (
                                                            <label key={`${shipOption?.serviceName}-${index}`} className="labelStyle">
                                                                <Field
                                                                    type="radio"
                                                                    name="selectedShipOptions"
                                                                    value={shipOption.serviceType}
                                                                    checked={isFormReadOnly ? lastChosenOption === shipOption.serviceType : values.selectedShipOptions === shipOption.serviceType}
                                                                    disabled={isFormReadOnly}
                                                                    onChange={isFormReadOnly ? undefined : handleShippingOptionChange(shipOption.serviceType, shipOption.deliveryDate, handleChange)}
                                                                />
                                                                {`${shipOption.serviceName?.replace('FedEx', '').replace(/®/g, '')} - $${shipOption?.cost}`}
                                                            </label>
                                                        ))
                                                    )
                                                }
                                            </Stack>

                                            <hr className="mt-5" />
                                            <DateTable caption="Target Delivery Date" dateValues={expectedDateValues} />
                                            <DateTable caption="Actual Expected Delivery Date" dateValues={convertDateToValues(actualDeliveryDate)} />

                                            <Container>
                                                <Image src={uLab_Horiz_Logo} fluid />
                                            </Container>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </FormikForm>
                        )
                    }
                    }
                </Formik>
            </Card>
        </Row>
        {/* </Container> */}
        {/* </div> */}
    </>
    )
}

export default ShippingForm;