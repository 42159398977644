import { useOktaAuth } from "@okta/okta-react"
import { Button } from "react-bootstrap"
import '../../theme/components/button.css'
import { useNavigate } from "react-router"



const LogoutButton = () => {
    const { oktaAuth } = useOktaAuth()
    const navigate = useNavigate()

    const logout = async () => {
        oktaAuth.signOut();
        navigate('login')
    }

    return (
        <div className="text-center">
            <Button variant="light" className="ml-button" onClick={logout} style={{ backgroundColor: "#4a00d5", color: "white" }}>
                <h6>Logout</h6>
            </Button>
        </div>
    )
}

export default LogoutButton