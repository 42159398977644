import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface AuthState {
  accessToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state: AuthState, action: PayloadAction<string>) => {
      // console.log(`setAccessToken(${action.payload})`);
      state.accessToken = action.payload;
    },
    clearAccessToken: (state: AuthState) => {
      console.log(`clearAccessToken: called with ${state.accessToken}`);
      state.accessToken = null;
      console.log(`clearAccessToken: exiting with ${state.accessToken}`);
    },
  },
});

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAuthBearerHeader = (state: RootState) => {
  // console.log(
  //   `in selectAuthBearerHeader, about to return - '${state.auth.accessToken}'`
  // );
  return `Bearer ${state.auth.accessToken}`;
};
export const selectIsLoggedIn = (state: RootState): boolean => {
  const ans = !(state.auth.accessToken === null);
  // console.log(`selectIsLoggedIn returning ${ans}...`);
  return ans;
};

export const { setAccessToken, clearAccessToken } = authSlice.actions;
export default authSlice.reducer;
