import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"
import { selectIsLoggedIn, setAccessToken } from "./authSlice"
import { useOktaAuth } from "@okta/okta-react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { AccessToken, UserClaims } from "@okta/okta-auth-js"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"


const LoginButton = ({ doLogin }: { doLogin: () => Promise<void> }) => {
    return (
        <div>
            <Container>
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <Card className="shadow">
                            <Card.Body>
                                <div className="mb-3 mt-md-4">
                                    <h2>UShip</h2>

                                    <div className="mb-3">

                                        <div className="d-grid gap-2 mt-3 btn-hover">
                                            <Button onClick={() => { doLogin() }}>Login</Button>
                                        </div>

                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>

    )

}



const AuthedRoute = () => {

    const { oktaAuth, authState } = useOktaAuth()
    const pendingLogin = useRef(false)
    const location = useLocation()
    const dispatch = useAppDispatch()
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const [errMsg, setErrMsg] = useState("")


    const handleAuthRequired: () => Promise<void> = async () => {
        if (pendingLogin.current || authState?.isAuthenticated) {
            console.log(`authed already - ${JSON.stringify(authState?.accessToken?.accessToken)}`)
            if (authState?.accessToken?.accessToken) dispatch(setAccessToken(authState?.accessToken?.accessToken))
            return
        }
        pendingLogin.current = true
        //we will need this when we get calledback to from okta
        //localStorage.setItem('u-go-back-to', JSON.stringify(location))

        try {
            const originalUri = location.pathname
            await oktaAuth.signInWithRedirect({ originalUri })
        }
        catch (e) {
            setErrMsg(JSON.stringify(e))
            pendingLogin.current = false
        }

    }

    useEffect(() => {
        (async () => {
            const accessToke: AccessToken = await oktaAuth.tokenManager.get('accessToken') as AccessToken
            // console.log('Access token:', accessToke);
            if (accessToke) {
                // JSON.parse(JSON.stringify(accessToke)); // This will throw an error if the access token is not valid JSON
                dispatch(setAccessToken(accessToke.accessToken))
            }

            if (!oktaAuth.isLoginRedirect() || !pendingLogin.current)
                return

            try {
                const tokesResp = await oktaAuth.token.parseFromUrl()
                // console.log('Tokens:', tokesResp.tokens);
                // JSON.parse(JSON.stringify(tokesResp.tokens)); // This will throw an error if the tokens are not valid JSON
                oktaAuth.tokenManager.setTokens(tokesResp.tokens)
                const accessToke: AccessToken = await oktaAuth.tokenManager.get('accessToken') as AccessToken

                console.log(`found accessToken: ${JSON.stringify(accessToke)} - dispatch(${accessToke.accessToken})`)

                dispatch(setAccessToken(accessToke.accessToken))
            }
            catch (err) {
                setErrMsg(`error parsing tokens from url and storing them in tokenManager - ${JSON.stringify(err)}!`)
            }
            finally { pendingLogin.current = false }
            const userInfo: UserClaims = await oktaAuth.getUser();
            // console.log(`userInfo: ${JSON.stringify(userInfo)}`)
            // setUserEmail(userInfo.email || null)
        })()
    }, [dispatch, oktaAuth, oktaAuth.isLoginRedirect, pendingLogin])

    if (errMsg)
        return (<div><h3>{errMsg}</h3></div>)

    if (pendingLogin.current)
        return (<div>logging in...</div>)

    if (!isLoggedIn || authState == null || !authState?.accessToken)
        return <LoginButton doLogin={handleAuthRequired} />

    return <Outlet />
}

export default AuthedRoute