const oktaConfig = {
  issuer: process.env.REACT_APP_OKTA_ISSUER as string, //'https://dev-82518731.okta.com/oauth2/default',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string, // '0oa6rqmz9xHpLYxRL5d7',   //   //
  redirectUri: `${window.location.origin}/login`, //${process.env.REACT_APP_REDIRECT_PATH as string `,
  pkce: true,
  disableHttpsCheck: true,
  scopes: (process.env.REACT_APP_OKTA_SCOPES as string).split(' '),
};

// console.log(`oktaConfig: ${JSON.stringify(oktaConfig)}`);

export default oktaConfig;
