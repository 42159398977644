import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useGetUshipQuery, ushipApi } from "../uship/ushipApi";
import { useAppDispatch } from "../../app/hooks";
import { Button, Container, Form, Modal, Stack } from "react-bootstrap";
import uLab_Circle_RGB from '../../assets/images/uLab_Circle_RGB.png';
import uLab_Logo_RGB from '../../assets/images/uLab_Logo_RGB.png';
import '../../theme/components/card.css'
import LogoutButton from "./LogoutButton";

export const OrderEntry = () => {


    const [orderNumber, setOrderNumber] = useState('');
    const [submittedOrderNumber, setSubmittedOrderNumber] = useState('');
    const [showPleaseWait, setShowPleaseWait] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const { data: ushipData, isLoading: isUshipLoading, isError: isUshipError, error: ushipError } = useGetUshipQuery(submittedOrderNumber);

    useEffect(() => {
        const checkFocus = () => {
            if (inputRef.current && document.activeElement !== inputRef.current) {
                inputRef.current.focus();
            }
        }

        document.addEventListener('click', checkFocus)
        return () => document.removeEventListener('click', checkFocus)
    }, [])



    const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setShowPleaseWait(true)
        // const orderNumber = orderNumberText.trim()

        if (orderNumber.length < 1) {
            console.log(`odd.  handleOnSubmit for orderIdInput was called with a blank orderNumber - ${orderNumber}.`)
            setShowPleaseWait(false)
            setShowModal(true);
            return
        }


        console.log(`looking up order ${orderNumber}...`)
        setSubmittedOrderNumber(orderNumber);
    };

    useEffect(() => {
        if (isUshipLoading) {
            return;
        }
        if (isUshipError) {
            console.log(`Error fetching Uship data: ${typeof ushipError === 'object' ? ushipError.message : ushipError}`)
            if (typeof ushipError === 'object' && 'status' in ushipError && ushipError.status === 404) {
                setShowModal(true);
            }
            setShowPleaseWait(false);
            return;
        }

        if (ushipData) {
            setShowPleaseWait(false)
            navigate(`/${orderNumber}`);
        }
    }, [isUshipLoading, isUshipError, ushipData]);

    const OrderError = () => {

        return (
            <Modal show={showModal} onHide={() => { setShowModal(false); navigate('/'); setOrderNumber(''); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>Invalid order number</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); navigate('/'); setOrderNumber(''); }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }


    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderNumber(e.target.value);
        e.preventDefault();
    };

    return (
        <>

            <div className="bground-image" style={{ backgroundImage: `url(${uLab_Circle_RGB})` }}>
                <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={uLab_Logo_RGB} alt="uShip" style={{ width: '100px', height: 'auto' }} />
                    <Container>
                        <OrderError />
                    </Container>
                    <LogoutButton />
                </div>
                <div className='bground' style={{ flexGrow: 1, overflow: 'auto' }}>
                    <h5 className="card-title">Please Scan Order</h5>
                    <Form onSubmit={handleOnSubmit}>
                        <Stack direction="horizontal" gap={2}>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Order Id"
                                    onChange={handleOnChange}
                                    autoFocus
                                    ref={inputRef}
                                />
                            </Form.Group>
                            <div className="btn-hover">
                                <button className="btn btn-primary" type="submit" disabled={showPleaseWait}>
                                    {showPleaseWait ? (
                                        <>
                                            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                            <span role="status">Loading...</span>
                                        </>
                                    ) : 'Submit'}
                                </button>
                            </div>
                        </Stack>

                    </Form>
                </div>
            </div>
        </>
    )
}
